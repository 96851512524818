import React from 'react'
import './LanDeveloper.css'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import SectionOne from '../../components/section/SectionOne'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'

function LanDeveloper() {
  return (
    <div className='lan_developer'>
      <NavBar />
      <SectionOne
        sectionOneData={
          {
            title: "IQLand: Redefining Land Development with AI-Powered Precision",
            subtitle: "Revolutionizing Real Estate Projects with Data-Driven Insights",
            paragraph: "At IQLand, we simplify the complexities of land development by providing AI-powered tools that help you unlock your project’s full potential. From analyzing parcel data to offering actionable recommendations, we empower you to make smarter, faster decisions every step of the way.",
            image: "/images/lan_home/realestate_dev_1.png",
            alt: "Image of Developer"
          }
        }
      />

      <div className='lan_copilot_featurs lan_container'>
        <div className='iqland_motto'>Interactive Parcel Analysis: Unlock Your Project's Potential</div>
        <div className='lan_copilot_featurs_data'>
          <div className='lan_copilot_features_items'>
            <div className='lan_copilot_item lan_developer_item'>
              <img src="/images/lan_home/fea_ico_1.png" alt='icon' />
              <div>
                <div className='lan_card__title'>Feasibility in Minutes</div>
                <p className='lan_normal_text'>Quickly determine what’s possible for your parcel with instant insights into zoning, entitlements, and building potential—no more waiting weeks for answers.</p>
              </div>
            </div>
            <div className='lan_copilot_item lan_developer_item'>
              <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
              <div>
                <div className='lan_card__title'>Discover Hidden Opportunities</div>
                <p className='lan_normal_text'>Leverage powerful data-driven insights to uncover possibilities you may have missed, ensuring no valuable options are left unexplored.</p>
              </div>
            </div>
            <div className='lan_copilot_item lan_developer_item'>
              <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
              <div>
                <div className='lan_card__title'>Save Time and Money</div>
                <p className='lan_normal_text'>Cut through months of delays with our streamlined analysis tools, allowing you to make faster decisions and move your project forward confidently—because time is money.</p>
              </div>
            </div>
          </div>
          {/* <img className='lan_copilot_featurs_img' src='/images/lan_home/copilot_2.png' alt='copilot' /> */}
        </div>

      </div>

      <div className='lan_home__why_iqland lan_developer_why_iqland'>
        <div className='iqland_motto' style={{ marginTop: "2rem" }}>Key Performance Indicators (KPIs) at Your Fingertips</div>
        
        <div className='lan_home__why_iqland_cards'>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_2.png' alt='why' />
            <div className='lan_card__title'>Zoning Compliance</div>
            <p className='lan_normal_text'>Ensure your project aligns with local zoning rules, maximizing land use and avoiding delays.</p>
          </div>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_3.png' alt='why' />
            <div className='lan_card__title'>Cost Efficiency</div>
            <p className='lan_normal_text'>Get accurate cost estimates to plan budgets and optimize financial decisions.</p>
          </div>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_4.png' alt='why' />
            <div className='lan_card__title'>Profitability Analysis</div>
            <p className='lan_normal_text'>Analyze potential returns with AI-driven insights, factoring in costs and market trends.</p>
          </div>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_4.png' alt='why' />
            <div className='lan_card__title'>Value After Completion Metrics</div>
            <p className='lan_normal_text'>Project your property’s future value with data-backed metrics for smarter planning.</p>
          </div>
        </div>
        
      </div>

      <div className='lan_partner_iq'>

        <img className='lan_partner_iq__right' src="/images/lan_home/meeting_1.png" alt="partner" />
        <div className='lan_partner_iq__left'>
          <div className='iqland_motto' style={{ color: "white", marginBottom: "2rem" }}>Streamline Your Development Process</div>
          <div className='lan_partner_items'>
            <div className='lan_partner_item'>
              <img src='/images/lan_home/part_ico_1.png' alt='icon' />
              <div className='lan_partner_item_texts'>
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Quick Decision-Making</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Determine your project's potential in minutes with AI-powered feasibility insights, helping you decide to move forward or pass without wasting time.</p>
              </div>
            </div>
          </div>
          <div className='lan_partner_items'>
            <div className='lan_partner_item'>
              <img src='/images/lan_home/part_ico_2.png' alt='icon' />
              <div className='lan_partner_item_texts'>
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Eliminate Wasted Costs</div>
                <p className='lan_normal_text' style={{ color: "white" }}>By generating preliminary site plans and recommending only plans that align with your parcel, we help you save thousands on plans you won’t use. Don’t call a surveyor or purchase plans until you’re ready to proceed. Cut your due diligence costs by up to 90%.</p>
              </div>
            </div>
          </div>
          <div className='lan_partner_items'>
            <div className='lan_partner_item'>
              <img src='/images/lan_home/part_ico_2.png' alt='icon' />
              <div className='lan_partner_item_texts'>
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Streamline Project Research</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Use our AI-powered SmartBuild dashboard to centralize data, analyze opportunities, and move from research to action faster than ever.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ActionSection/>
      <JoinIqlandToday/>
      <Footer />
    </div>
  )
}

export default LanDeveloper