import React from 'react'
import NavBar from '../../components/navbar/NavBar'
import SectionOne from '../../components/section/SectionOne'
import Footer from '../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import { Dataset, RocketLaunch, WatchLater } from '@mui/icons-material'
import './LanAppraisar.css'

function LanAppraisar() {

    const navigate = useNavigate();

    return (
        <div className='lan_agent'>
            <NavBar />
            <SectionOne
                sectionOneData={
                    {
                        title: "Appraisal IQ: Fast, Reliable Property Valuations",
                        // subtitle: "Unlocking the Potential of Every Parcel",
                        paragraph: "Appraisal IQ transforms the property valuation process by delivering accurate, non-certified appraisal reports in minutes. Say goodbye to waiting weeks or paying high fees for traditional appraisals—get the insights you need, fast and affordably.",
                        // paragraph2: "Use IQLand to uncover new listing opportunities with ease. Find property owners’ names, contact details, and key parcel information in one place, or ask our Copilot to highlight opportunities in your zip code. Simplify your search and grow your portfolio effortlessly.",
                        image: "/images/lan_appraisar/appraisal_hero.png",
                        alt: "Image of Appraiser"
                    }
                }

            />

            <div className='appraisar__features'>
                <div className='appraisar__secion_title' data-aos="fade-up" data-aos-duration="1500">Key Benefits</div>
                <hr className='appraisar__section_hr' />
                
                <div className='appraisar__features_points'>
                    <div className='appraisar__features_item'>
                        <RocketLaunch className='appraisar__features_icon' />
                        <div className='appraisar__features_item_text'>
                            <div className='appraisar__features_item_text_title'>Instant Appraisal Reports</div>
                            Receive detailed, non-certified appraisal reports within minutes. No more waiting weeks for results—make informed decisions faster.

                        </div>
                    </div>
                    <div className='appraisar__features_item' data-aos="fade-down" data-aos-duration="1800">
                        <WatchLater className='appraisar__features_icon' />
                        <div className='appraisar__features_item_text'>
                            <div className='appraisar__features_item_text_title'>Affordable Valuations </div>
                            Skip the high costs of traditional appraisals. With Appraisal IQ, you get reliable reports at a fraction of the time and price.
                        </div>
                    </div>
                    <div className='appraisar__features_item' data-aos="fade-down" data-aos-duration="2000">
                        <Dataset className='appraisar__features_icon' />
                        <div className='appraisar__features_item_text'>
                            <div className='appraisar__features_item_text_title'>Data-Rich Insights</div>
                            Access property-specific insights, including comparable sales, neighborhood trends, school districts, and potential upgrades' impact on value—all in one place.
                        </div>
                    </div>

                </div>
            </div>

            <div className='lan_copilot_exp'>
                <div className='lan_copilot_exp_texts'>
                    <div className='lan_title' style={{ color: "white" }} data-aos="fade-up" data-aos-duration="2000">Why Appraisal IQ?</div>
                    <p className='lan_normal_text' style={{ color: "white" }} data-aos="fade-up" data-aos-duration="2000">Appraisal IQ empowers you to save time, cut costs, and make confident decisions. With fast and affordable reports, you’ll streamline your workflow, impress your clients, and focus on what really matters—closing deals.</p>
                    <div
                        className='lan_btn_white'
                        style={{ textWrap: "nowrap", width: "20rem" }}
                        onClick={() => navigate("#")}
                        data-aos="zoom-in"
                    >
                        Get Your Appraisal Report Now
                    </div>
                </div>
                <img src='/images/lan_appraisar/appraisal_why.png' alt='hands' data-aos="fade-left" data-aos-duration="2000" />
            </div>
            <Footer />
        </div>
    )
}

export default LanAppraisar