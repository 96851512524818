import React from 'react'
import './TermConditions.css'
import ChatFormat from '../../../components/chatbot_msg_components/ChatFormat'
import { msg } from './data'

function TermConditions() {

    return (
        <div className='terms_and_conditions'>
            
            {/* 
                <div className='term_item'>
                    <div className='term_item_title'>1. Introduction</div>
                    <div className='term_item_content'>Welcome to IQLand. By accessing or using our platform and services, you agree to be bound by these Terms and Conditions. Please read these terms carefully before using our platform. If you do not agree to these terms, you must discontinue your use of the platform.</div>
                </div>
            </div> */}
            <div className='term_items'>

                <ChatFormat msg={msg} />
            </div>
        </div>
    )
}

export default TermConditions