import React, { useContext } from 'react'
import "./JoinIqlandToday.css"
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../components/auth/AuthenticationContext';
import { LoadingSkeletonText } from '../../../../components/skeleton_loading/SkeletonLoading';

function JoinIqlandToday() {

  const navigate = useNavigate();

  const { user, loading } = useContext(AuthContext);

  return (
    <div className='join_iqland_today'>
      <div className='lan_title' style={{ color: "white" }}>Join IQLand today!</div>
      <p className='lan_normal_text' style={{ color: "white" }}>Explore and learn how land development works with IQLand. Get valuable insights and try out our tools to streamline your projects.</p>
    
      {
        !loading ?
          <div
            className='lan_btn_gold' style={{ color: "var(--primary-clr)" }}
            onClick={() => user ? navigate("/copilot") : navigate("/register")}>
            Register
          </div>
          :
          <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
      }
    </div>
  )
}

export default JoinIqlandToday