import React, { useState } from 'react'
import './TulsaLanding.css'
import LanReviews from '../landing_pages/components/lan_reviews/LanReviews'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import IQSpinner from '../../components/util_component/iq_spinner/IQSpinner'
import TulsaDemoSingle from './TulsaDemoSingle'
import { msg } from './MsgData'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { preMessage } from '../copilot_new/data/PreMessage'

function TulsaLanding() {

    const customerReviewData = [
        {
            name: "Alex T.",
            review: "IQLand saved us months of back-and-forth with the city. We knew exactly what we could build, and we got approval fast!",
            job: "Real Estate Developer",
            profile: "alex.jpg"
        },
        {
            name: "Sarah D.",
            review: "As a seller, the instant appraisal tool was a game-changer. I knew the value of my home in minutes.",
            job: "Homeowner",
            profile: "sarah.jpg"
        },
        {
            name: "Michael C.",
            review: "IQLand’s cost estimator has made budgeting a breeze. I can’t imagine working on a project without it now.",
            job: "Property Developer",
            profile: "michael.jpg"
        }
    ]



    const [fullChat, setFullChat] = useState([]);


    const [isLoading, setIsLoading] = useState(false);

    const [count, setCount] = useState(0);

    const [inputText, setInputText] = useState(msg[0].text)

    const handleSendMessage = (message) => {
        let tempCount = count;
        if (count !== 8) {
            setIsLoading(true);
            setFullChat(prev => [...prev, message[tempCount]]);
            setTimeout(() => {
                setFullChat(prev => [...prev, message[tempCount + 1]]);
                setCount(count + 2);
                setIsLoading(false);
                if (count !== 6) {
                    setInputText(msg[tempCount + 2].text)
                }
                else {
                    setInputText("Try it now")
                }

            }, 1500);
        }
        else {
            window.location.href = "/register"
        }

    };

    return (
        <div className='tulsa_landing'>
            <NavBar />
            <div className='tl_hero_page'>
                <div className='tl_hero_left'>
                    <div className='tl_title'>HEY OKLAHOMA</div>
                    <div className='tl_title' style={{marginTop:"-1.5rem"}}>You've landed at IQLand.</div>
                    <div className='tl_title' style={{marginTop:"-1.5rem"}}>Destination Home.</div>
                    <div className='tl_subtitle'>IQLand is the essential research tool for residential real estate developers, agents, builders, and homeowners.</div>
                    <div className='tl_trial_text'>Start Your Free 7-Day Trial Today!</div>
                    <div onClick={() => window.location.href = "/register"} className='lan_btn_gold' style={{ color: "var(--primary-clr)", marginBottom: "2rem" }}>Try for Free</div>
                </div>
                <div className='tl_hero_right'><img src='/images/lan_tulsa/hero1.png' alt='tulsa' /></div>
            </div>

            <div className='tl_demo_copilot'>


                <div className='tl_demo_copilot_content'>
                    <div className="tl_demo_copilot_right">
                        <div className='tl_title' style={{ fontSize: "2rem", marginBottom: "2rem", textAlign: "left" }}>Get the Answers You Need to Build Your Dream Home</div>
                        <div className='tl_demo_copilot_right_item'>
                            <div className='tl_demo_copilot_right_title'>What Can I Build?</div>
                            <div className='tl_demo_copilot_right_text'>
                                Instantly know what home plans are allowed on any parcel of land in Oklahoma.
                            </div>
                        </div>
                        <div className='tl_demo_copilot_right_item'>
                            <div className='tl_demo_copilot_right_title'>What Will It Cost?</div>
                            <div className='tl_demo_copilot_right_text'>
                                Get a clear estimate of project costs for any build.
                            </div>
                        </div>
                        <div className='tl_demo_copilot_right_item'>
                            <div className='tl_demo_copilot_right_title'>What Will I Make?</div>
                            <div className='tl_demo_copilot_right_text'>
                                Receive data-driven projections of your potential returns on investment.
                            </div>
                        </div>
                        <div className='tl_demo_copilot_right_item'>
                            <div className='tl_demo_copilot_right_title'>Will the City Approve It?</div>
                            <div className='tl_demo_copilot_right_text'>
                                Understand zoning laws and get approval estimates in minutes, not months.
                            </div>
                        </div>
                    </div>
                    <div className="tl_demo_container">
                        <div className='tl_demo_window'>
                            <div className='co_main_area__chat_area' style={{ padding: "0", height: "60vh", overflowY: "auto", position: "relative" }}>
                                {

                                    fullChat.map((message, index) => (
                                        <TulsaDemoSingle message={message} key={index} />
                                    ))
                                }
                                {
                                    isLoading && <IQSpinner />
                                }
                                {
                                    fullChat.length === 0 && <div className='demo_logo'>
                                        <img style={{ height: "3rem" }} src="/black_icon.svg" alt='iqland' />
                                        <div style={{ textWrap: "nowrap", textAlign: 'center' }}>let's get started</div>
                                        {
                                            preMessage.map((m) => (
                                                <div className='copilot_new_chat__card' style={{width:"100%"}}>
                                                    <h6 className='demo_logo_area_card_title'>{m.title}</h6>
                                                    <div className='demo_logo_area_card_content'>{m.content}</div>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>
                                }
                            </div>

                            <div className="tulsa_chat-input-container" style={{ width: "100%", backgroundColor: "rgba(255,255,255,0.8" }}>
                                <textarea
                                    placeholder="Enter address or Parcel ID"
                                    rows="1"
                                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                                    value={inputText}
                                    disabled
                                />

                                <div className='tulsa_send_button_area'>
                                    <button onClick={() => !isLoading && handleSendMessage(msg)} className='tulsa_send_button'>
                                        {
                                            !isLoading ? <img src="/images/copilot_new/send_icon.svg" alt='send' /> : <IQSpinner />
                                        }
                                    </button>
                                    {fullChat.length === 0 && <div onClick={() => !isLoading && handleSendMessage(msg)} className='tulsa_send_button_press_me'>&nbsp;</div>}
                                    {fullChat.length === 0 && <div className='tulsa_send_button_press_me_text'>Press Me</div>}
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>

            <div className='tl_iqland_tool'>
                <img className='tl_iqland_tool_left' src="/images/lan_tulsa/dashboard_1.png" alt="dashboard" />
                <div className='tl_iqland_tool_right'>
                    <div className='tl_title'>Find Out Your Home's Value with Our Instant Appraisal Tool</div>
                    <div className='tl_text'>Use IQLand's appraisal feature to get real-time, data-driven home valuations that help you sell with confidence.</div>
                </div>
            </div>

            <div className='tl_steps'>
                <div className='tl_title'>It's Simple. Here's How IQLand Works</div>
                <div className='tl_steps_cards'>

                    <div className='tl_step_item'>
                        <div className='tl_step_card'>
                            <img src='/images/lan_tulsa/step1.png' alt='step3' />
                        </div>
                        <div className='tl_step_card_title_area'>
                            <div className='tl_step_card_number'>1</div>
                            <div className='tl_step_card_texts'>
                                <div className='tl_step_card_text_title'>Pick a Parcel</div>
                                <div className='tl_step_card_text_normal'>Enter the land address or location you're interested in.</div>
                            </div>
                        </div>
                    </div>

                    <div className='tl_step_item' style={{ flexDirection: "row-reverse" }}>
                        <div className='tl_step_card2' style={{ borderRadius: "20rem 0 0 20rem" }}>
                            <img src='/images/lan_tulsa/step2.png' alt='step3' />
                        </div>
                        <div className='tl_step_card_title_area'>
                            <div className='tl_step_card_number'>2</div>
                            <div className='tl_step_card_texts'>
                                <div className='tl_step_card_text_title'>Get Your Answers</div>
                                <div className='tl_step_card_text_normal'>Receive immediate answers to the 4 big questions.</div>
                            </div>
                        </div>
                    </div>

                    <div className='tl_step_item'>
                        <div className='tl_step_card'>
                            <img src='/images/lan_tulsa/step3.png' alt='step3' />
                        </div>
                        <div className='tl_step_card_title_area'>
                            <div className='tl_step_card_number'>3</div>
                            <div className='tl_step_card_texts'>
                                <div className='tl_step_card_text_title'>Plan, Build, or Sell</div>
                                <div className='tl_step_card_text_normal'>Use IQLand's recommendations to start your project or sell your home.</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className='lan_reviews' style={{ marginBottom: "2rem" }}>
                <div className='iqland_motto' style={{ textAlign: "center" }}>What our customers <span>say</span></div>
                <div className='lan_review_cards'>
                    {
                        customerReviewData.map((item, index) => (
                            <LanReviews key={index} comments={item.review} cusName={item.name} job={item.job} image={item.profile} />
                        ))
                    }
                </div>
            </div>

            <div className='join_iqland_today join_iqland_padding'>
                <div className='lan_title' style={{ color: "white" }}>Know What You Can Build or Sell in Minutes, Not Months.</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Start your Free 7-Day Trial and explore IQLand's powerful tools.</p>

                <div
                    className='lan_btn_gold' style={{ color: "var(--primary-clr)" }}
                    onClick={() => window.location.href = "/register"}>
                    Try It Now
                </div>
                <div style={{ color: "white" }}>Cancel anytime.</div>
            </div>

            <div className='lan_dashboard_plan_left' style={{ backgroundColor: "var(--primary-clr)" }}>
                <div className='iqland_motto' style={{ color: "white" }}>FAQs</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Got Questions? We’ve Got Answers.</p>
                <div>

                    <Accordion className='lan_dashboard_plan_left_acc'>
                        <AccordionSummary
                            sx={{ borderStyle: "solid", borderWidth: "0 0 1px 0", borderColor: "white", padding: "0" }}
                            expandIcon={<ExpandMoreOutlined sx={{ border: "1px solid white", borderRadius: "50%", color: "white" }} />}
                        >
                            <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>How accurate is IQLand’s home valuation tool?</div>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ paddingLeft: "0", paddingRight: "0" }}
                        >
                            <div className='lan_normal_text' style={{ color: "white" }}>Our tool uses real-time market data and algorithms to provide you with the most up-to-date and accurate home valuations.</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='lan_dashboard_plan_left_acc'>
                        <AccordionSummary
                            sx={{ borderStyle: "solid", borderWidth: "0 0 1px 0", borderColor: "white", padding: "0" }}
                            expandIcon={<ExpandMoreOutlined sx={{ border: "1px solid white", borderRadius: "50%", color: "white"}} />}
                        >
                            <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Will IQLand work for properties outside of Oklahoma?</div>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ paddingLeft: "0", paddingRight: "0" }}
                        >
                            <div className='lan_normal_text' style={{ color: "white" }}>Currently, IQLand focuses on properties in Oklahoma, with plans to expand soon!</div>
                        </AccordionDetails>
                    </Accordion>


                </div>
            </div>

            <Footer />
        </div>
    )
}

export default TulsaLanding