import React from 'react'
import NavBar from '../../components/navbar/NavBar'

function SuccessSubmit() {
  return (
    <div>
        <NavBar/>
        <div className='pp_thank_you_container'>
            <div className='pp_thank_you_emoji' data-aos="zoom-in" data-aos-duration="500">🎊</div>
            <h1 className='pp_thank_you_title'>Thank you for your interest in IQLand.</h1>
            <p className='pp_thank_you_para'>In the meantime, you can view our SPV and data room here.</p>
            <div className='pp_thank_you_button' onClick={() => window.location.href = 'https://drive.google.com/drive/folders/1ppPbWOuUMHMavG0t1Hw_BWFqUTlOj5hC?usp=share_link'}>Data Room</div>
        </div>
    </div>
  )
}

export default SuccessSubmit