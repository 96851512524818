import React, { useRef } from 'react'
import './UserNavTulsa.css'
import { useNavigate } from 'react-router-dom'
import { NotesOutlined } from '@mui/icons-material';
function UserNavTulsa({ tab, handleBurgerClick }) {

  const navigate = useNavigate();



  // const { logOut } = useContext(AuthContext);
  // const [loading, setLoading] = useState(false);


  // handle outside click
  const profileTabs = useRef(null);

  const copilotNavigation = () => {
    const chatId = sessionStorage.getItem("chatID");
    if (chatId) {
      navigate(`/tulsa/copilot?chat=${chatId}`)
    }
    else {
      navigate("/tulsa/copilot")
    }
  }

  return (
    <nav className='user_nav_tulsa'>
      {/* Logo iqland */}
      <div className='user_nav__burger_icon' onClick={() => handleBurgerClick()}><NotesOutlined /></div>
      <div className='user_nav_tulsa_logo_area'  onClick={() => window.location.href = "/tulsa/copilot"}>
        <img src='/images/tulsa/tulsa_logo.svg' alt='IQLand' className='user_nav__logo_top' style={{ height: "2.5rem" }} />
        {/* <div className='tulsa_logo_text'>Tulsa Approved Plans Portal</div> */}
      </div>


      <div style={{ display: "flex", alignItems: "center", gap: "2.5rem" }}>
        {/* tabs */}
        <ul className='user_nav__tabs'>
          <li className={tab === "c" ? "user_nav__active_tab" : null} onClick={() => copilotNavigation()}>Home</li>
          <li className={tab === "d" ? "user_nav__active_tab" : null} onClick={() => navigate("/tulsa/dashboard")}>Dashboard</li>
          {/* <li className={tab === "r" ? "user_nav__active_tab" : null} onClick={() => navigate("/reports")}>Reports</li> */}
        </ul>

        {/* profile area */}
        <div ref={profileTabs} className='user_nav_profile_area' >

          <div className='user_nav_profile' style={{ cursor: "pointer" }}>
            <img src="/images/copilot_new/iq_profile_image.svg" onClick={() => window.location.href = "/"} alt='profile' />
          </div>

        </div>
      </div>

      {/* 
      {
        loading && <IQLoading message={"Logging out..."} />
      } */}
    </nav>
  )
}

export default UserNavTulsa