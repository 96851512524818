import "./App.css";
import { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import MunicipalityDemo from "./pages/municipality/MunicipalityDemo";
import MunicipalityDemoTab from "./pages/municipality/MunicipalityDemoTab";
import SubscriptionPlans from "./pages/subscription_plans/SubscriptionPlans";
import { AuthProvider } from "./components/auth/AuthenticationContext";
import {
  AuthenticationRoute,
  LoggedOutRoute,
  SubscriptionRoute,
} from "./components/auth/AuthRoute";
import CopilotNew from "./pages/copilot_new/CopilotNew";
import OnBoarding from "./pages/onboarding/OnBoarding";
import DashboardNew from "./pages/dashboard_new/DashboardNew";
import CoReports from "./pages/co_reports/CoReports";
import Favorites from "./pages/favorites/Favorites";
import PersonalInfo from "./pages/settings/PersonalInfo";
import ProfInfo from "./pages/settings/ProfInfo";
import GeoDetails from "./pages/settings/GeoDetails";
import SecuritySetting from "./pages/settings/SecuritySetting";
import BillingSetting from "./pages/settings/BillingSetting";
import SocialSetting from "./pages/settings/SocialSetting";
import NotFoundPage from "./pages/not_found_page/NotFoundPage";
import NewLogin from "./pages/login/NewLogin";
import NewRegister from "./pages/register/NewRegister";
import NewVerifyOtp from "./pages/login/NewVerifyOtp";
import NewForgotEmail from "./pages/login/NewForgotPassword";
import NewLoginOtp from "./pages/login/NewLoginOtp";
import EventSubscriptions from "./pages/event_subscriptions/EventSubscriptions";
import { SuccessfullyPurchased } from "./pages/event_subscriptions/ResponsePage";
import ResetPassword from "./pages/login/ResetPassword";
import LanHome from "./pages/landing_pages/LanHome";
import LanArchitect from "./pages/landing_pages/LanArchitect";
import LanCopilot from "./pages/landing_pages/LanCopilot";
import LanDashboard from "./pages/landing_pages/LanDashboard";
import LanDeveloper from "./pages/landing_pages/LanDeveloper";
import FaqPage from "./pages/landing_pages/FaqPage";
import ContactUs from "./pages/contact/ContactUs";
import Payment from "./pages/checkout/Payment";
import { loadStripe } from "@stripe/stripe-js";
import UserHome from "./pages/user_home/UserHome";
import { SubsProvider } from "./components/auth/SubscriptionContext";
import DashboardTulsa from "./pages/dashboard_tulsa/DashboardTulsa";
import CopilotTulsa from "./pages/copilot_tulsa/CopilotTulsa";
import LanAgent from "./pages/landing_pages/LanAgent";
import TulsaLanding from "./pages/tulsa_landing_page/TulsaLanding";
import MetaPixel from "./components/meta-pixel";
import Terms from "./pages/terms_conditions/Terms";
import GoogleAnalytics from "./components/google-analytics";
// import FinalReport from "./pages/copilot_new/component/pages/final_report/FinalReport";
import Aos from "aos";
import 'aos/dist/aos.css'
import LanAppraisar from "./pages/landing_pages/LanAppraisar";
import PlugAndPlay from "./pages/plug_and_play/PlugAndPlay";
import SuccessSubmit from "./pages/plug_and_play/SuccessSubmit";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
function App() {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_KEY));
    Aos.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function for animations
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <AuthProvider>
      <SubsProvider>
        <Router>
          <GoogleAnalytics measurementId="G-XXXXXXXXXX" />
          <MetaPixel pixelId="2383512208650633" />
          <Routes>
            <Route element={<SubscriptionRoute />}>
              <Route path="/copilot" element={<CopilotNew />} />
              <Route path="/copilot?chat={chat_id}" element={<CopilotNew />} />
              <Route path="/user/home" element={<UserHome />} />
              <Route path="/dashboard" element={<DashboardNew />} />

              {/* <Route path="/appraisal" element={<FinalReport />} /> */}
              <Route path="/reports" element={<CoReports />} />
              <Route path="/reports?id={report_id}" element={<CoReports />} />
              <Route path="/favorite" element={<Favorites />} />
              <Route path="/tulsa/dashboard" element={<DashboardTulsa />} />
              <Route path="/tulsa/copilot" element={<CopilotTulsa />} />
              <Route
                path="/tulsa/copilot?chat={chat_id}"
                element={<CopilotTulsa />}
              />
            </Route>

            <Route element={<AuthenticationRoute />}>
              {/* user setting tabs */}
              <Route path="/settings/personal" element={<PersonalInfo />} />
              <Route path="/settings/professional" element={<ProfInfo />} />
              <Route path="/settings/geographic" element={<GeoDetails />} />
              <Route path="/settings/security" element={<SecuritySetting />} />
              <Route
                path="/settings/billing"
                element={<BillingSetting stripePromise={stripePromise} />}
              />
              <Route path="/settings/social" element={<SocialSetting />} />

              {/* subscriptions */}
              {/* <Route
              path="/checkout"
              element={<Payment stripePromise={stripePromise} />}
            /> */}

              <Route
                path="/demo/municipalities/new"
                element={<MunicipalityDemo />}
              />
              <Route
                path="/demo/municipalities"
                element={<MunicipalityDemoTab />}
              />
              <Route
                path="/demo/municipalities?tabs={tab_name}"
                element={<MunicipalityDemoTab />}
              />
            </Route>

            <Route path="/" element={<LanHome />} />
            <Route path="/solutions/architects" element={<LanArchitect />} />
            <Route path="/solutions/developers" element={<LanDeveloper />} />
            <Route path="/solutions/agents" element={<LanAgent />} />
            <Route path="/products/copilot" element={<LanCopilot />} />
            <Route path="/products/dashboard" element={<LanDashboard />} />
            <Route path="/products/appraiser" element={<LanAppraisar />} />
            <Route path="/pricing" element={<SubscriptionPlans />} />
            <Route path="/faqs" element={<FaqPage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/tulsa/airport" element={<TulsaLanding />} />
            <Route path="/plugandplay" element={<PlugAndPlay/>}/>
            <Route path="/plugandplay/success" element={<SuccessSubmit/>}/>
            <Route path="/terms_and_conditions" element={<Terms />} />

            <Route element={<LoggedOutRoute />}>
              <Route path="/login" element={<NewLogin />} />
              <Route path="/register" element={<NewRegister />} />
              <Route path="/verify_email" element={<NewVerifyOtp />} />
              <Route path="/verify_login" element={<NewLoginOtp />} />
              <Route path="/forgot_password" element={<NewForgotEmail />} />
              <Route
                path="/reset-password/:firstSegment/:secondSegment/"
                element={<ResetPassword />}
              />
            </Route>
            {/* <Route path="/test" element={<TestPage />} /> */}
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/onboarding" element={<OnBoarding />} />
            <Route
              path="/event_subscriptions"
              element={<EventSubscriptions />}
            />
            <Route
              path="/checkout"
              element={<Payment stripePromise={stripePromise} />}
            />
            <Route
              path="/event/checkout/success"
              element={<SuccessfullyPurchased />}
            />
          </Routes>
        </Router>
      </SubsProvider>
    </AuthProvider>
  );
}

export default App;
