// export const preMessage = [
//     {
//       id:1,
//       title:"Subdivide a lot",
//       content:"Plot a maximized parcel subdivision for a plot of land."
//     },
//     {
//       id:2,
//       title:"Land use entitlements",
//       content:"What type of structure can I build on a parcel per local zoning regulations. FAR"
//     },
//     {
//       id:3,
//       title:"Find a home for me to build",
//       content:"Explore new construction home plans that qualify for your parcel. Modern, Craftsman, "
//     },
//     {
//       id:4,
//       title:"Run a feasibility report",
//       content:"What is the best scenario for making the most profit from this parcel?"
//     },
//     {
//       id:5,
//       title:"Local Construction costs",
//       content:"What is the price to build per square foot for a luxury, custom or builder grade home?"
//     },
//     {
//       id:6,
//       title:"Check my plans",
//       content:"Upload your construction or site plans to pre-determine legal compliance."
//     },
//   ]

  export const preMessage = [
    {
      id:1,
      title:"Zoning IQ",
      content:"Zoning type, Setbacks, FAR, Lot Coverage, Height and other zoning parameters"
    },
    {
      id:2,
      title:"Appraisal IQ",
      content:"Estimate the value of a residential property or parcel"
    },
    {
      id:3,
      title:"Allowed Structures",
      content:"Determine what building types are allowed on a parcel"
    },
    {
      id:4,
      title:"Construction Costs",
      content:"Local building costs for new construction"
    },
    {
      id:5,
      title:"Plan Recommendations",
      content:"Search plans recommended by IQLand for a parcel"
    },
    {
      id:6,
      title:"Parcel Info",
      content:"Lot size, Lot dimensions, Parcel ID, Owner's information"
    },
  ]