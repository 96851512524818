import React, { useEffect, useState } from 'react'
import './LanCopilot.css'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'
import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'
import "./LanCopilotAni.css"

function LanCopilot() {

    const isTabScreen = useMediaQuery({ query: '(max-width: 768px)' });


    const queryList = [
        "average building cost per square foot in this area?",
        "most cost-effective architectural plan available for a residential project on this parcel?",
        "zoning classification for my property and its implications on development potential?",
        "estimated value of the development upon completion based on current market trends?",

        "determine the best price to list a developed property based on its location and features?",
        "find architectural plans that match my client's specific needs and budget constraints?",
        "access up-to-date zoning information to advise clients on potential development opportunities?",
        "estimate the construction costs for a property renovation to help clients budget for their project?",

        "setbacks of the site plan to ensure compliance with local zoning requirements.",
        "floor to area ratio (FAR) provided in the construction plans against our jurisdiction's limits.",
        "lot coverage calculations in the submitted plans to verify adherence to permitted development densities.",
        "proposed building height and setbacks from the construction plans to ensure they meet our city's planning standards.",

        "the best construction plans for maximum ROI for this residential parcel.",
        "the ideal square footage for homes in this neighborhood.",
        "a modern home plan that meets the zoning requirements for this parcel.",
        "a 3 story Tudor style home for my parcel.",

        "build a single-family home with an accessory dwelling unit (ADU) on this parcel?",
        "increase the floor area ratio (FAR) to add more living space to the existing structure on this parcel?",
        "subdivide this lot to accommodate more than 1 house?",
        "build a multifamily home on this parcel?"
    ];

    const [currentQueryIndex, setCurrentQueryIndex] = useState(0);
    const [queryText, setQueryText] = useState("");
    const [currentTitle, setCurrentTitle] = useState("What is the...");

    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const currentQuery = queryList[currentQueryIndex];
            let index = 0;
            const innerInterval = setInterval(() => {
                if (index <= currentQuery.length) {
                    setQueryText(currentQuery.substring(0, index));
                    index++;
                } else {
                    clearInterval(innerInterval);
                    setCurrentQueryIndex((prevIndex) => (prevIndex + 1) % queryList.length);
                    setTimeout(() => {

                    }, 1000);
                }
            }, 50);

            if (queryText === queryList[3]) {
                setCurrentTitle("How can I...");
            }
            else if (queryText === queryList[7]) {
                setCurrentTitle("Analyze the...");
            }
            else if (queryText === queryList[11]) {
                setCurrentTitle("Recommend ...");
            }
            else if (queryText === queryList[15]) {
                setCurrentTitle("Can I ...");
            }
            else if (queryText === queryList[19]) {
                setCurrentTitle("What is the...");
            }

            return () => clearInterval(innerInterval);
        }, 1000);

        return () => clearInterval(interval);
    },);

    return (
        <div className='lan_copilot'>
            <NavBar />
            <div className='lan_copilot_hero'>
                <div className='lan_copilot_hero__left'>
                    <div className='lan_title' style={{ color: "white", marginBottom: "1rem" }} data-aos="fade-right">IQLand Copilot: AI-Enhanced Real Estate Development Assistant</div>
                    <p className='lan_normal_text' style={{ color: "white" }} data-aos="fade-right">Our Copilot is like having a real estate expert by your side, ready to answer anything—from simple questions to complex challenges—instantly and in your own words, giving you clear guidance to maximize your land's potential.</p>
                </div>
                <div className='lan_copilot_hero__right' data-aos="fade-up">
                    <img src={!isTabScreen ? "/images/lan_home/copilot_2.png" : "/images/lan_copilot/copilot_mobile.png"} alt='copilot' />
                </div>
            </div>

            <div className="hero-container" data-aos="zoom-our">
                <div>
                    <Row>
                        <Col md={12} lg={12} sm={12}>
                            <div className="left-column ">
                                <div className='center-sm'>
                                    <div>
                                        <h1>{currentTitle}</h1>
                                    </div>
                                    <div className="typed-text">
                                        <p>{queryText}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={6}>
                            <div className="right-column">
                                <div>
                                    <h1>Land intelligence done. <span>Fast.</span></h1>
                                    <p className="parah">IQLand is an AI powered copilot created for investors,
                                        developers, agents and cities to quickly, efficiently and accurately evaluate
                                        real estate development projects.</p>
                                    <div className='cto-section'>
                                        <p className="action-text">Join the waitlist</p>
                                        <Button variant="success" onClick={handleModal}>Join</Button>
                                    </div>

                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </div>

            <div className='lan_copilot_featurs'>
                <div className='iqland_motto' data-aos="fade-down">Key Features</div>
                <div className='lan_copilot_featurs_data'>
                    <div className='lan_copilot_features_items'>
                        <div className='lan_copilot_item' data-aos="fade-right" data-aos-duration="1500">
                            <img src="/images/lan_home/fea_ico_1.png" alt='icon' />
                            <div>
                                <div className='lan_card__title'>Instant Building Feasibility</div>
                                <p className='lan_normal_text'>Understand exactly what can be built on your parcel in seconds. The Copilot evaluates zoning, entitlements, and restrictions to give you quick, actionable insights.</p>
                            </div>
                        </div>
                        <div className='lan_copilot_item' data-aos="fade-right" data-aos-duration="1700">
                            <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
                            <div>
                                <div className='lan_card__title'>Interactive Parcel Intelligence</div>
                                <p className='lan_normal_text'>Ask detailed questions about your parcel, including ownership history, future land use plans, nearby school districts, and even building sentiment in the area.</p>
                            </div>
                        </div>
                        <div className='lan_copilot_item' data-aos="fade-right" data-aos-duration="2000">
                            <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
                            <div>
                                <div className='lan_card__title'>Instant ROI and Cost Analysis</div>
                                <p className='lan_normal_text'>Get a breakdown of potential profits, costs, and value across multiple approaches, including comparable sales, cost-based valuation, and income potential—all calculated on demand.</p>
                            </div>
                        </div>
                    </div>
                    <img className='lan_copilot_featurs_img' src='/images/lan_home/copilot_3.png' alt='copilot' data-aos="fade-down" data-aos-duration="2000" />
                </div>

            </div>

            {/* <div className='lan_copilot_key_features'>
                <img lassName='lan_copilot_key_featurs_img' src='/images/lan_home/key_feature_1.png' alt='key_features' />
                <div className='lan_copilot_key_features_right'>
                    <div className='lan_title' style={{ color: "var(--primary-clr)" }}>Benefits</div>
                    <div className='lan_gold_subtitle'>Get Personalized Guidance, Instantly</div>
                    <p className='lan_normal_text'>Ask customized questions and receive expert guidance, complete with sources and data to inform your decisions.</p>
                    {
                        !loading ?
                            <div
                                className='lan_primary_button'
                                onClick={() => user ? navigate("/copilot") : navigate("/register")}>
                                Get Started
                            </div>
                            :
                            <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
                    }
                </div>
            </div> */}

            <div className='lan_copilot_exp'>
                <div className='lan_copilot_exp_texts'>
                    <div className='lan_title' style={{ color: "white" }} data-aos="fade-up" data-aos-duration="2000">Unlock Efficiency and Clarity with IQLand</div>
                    <p className='lan_normal_text' style={{ color: "white" }} data-aos="fade-up" data-aos-duration="2000">IQLand Copilot isn’t just a tool—it’s your trusted partner in land development. Designed to simplify decision-making and streamline project planning, Copilot delivers powerful AI-driven insights to guide your every move. With Copilot, every decision is smarter, every opportunity clearer, and every project one step closer to success.</p>
                </div>
                <div className='sun_copilot_container'>
                    <img style={{zIndex:"1", position:"relative"}} src='/images/lan_copilot/sun_building.png' alt='hands' data-aos="fade-right"/>
                    <div style={{zIndex:"0"}} className='sun_copilot' ><img src='/images/lan_copilot/sun_copilot.png' alt='hands' data-aos="fade-left" data-aos-duration="3000"/></div>
                </div>
            </div>

            <ActionSection />
            <JoinIqlandToday />
            <Footer />
        </div>
    )
}

export default LanCopilot