import React, { useState } from 'react'
import './PlugAndPlay.css'
import NavBar from '../../components/navbar/NavBar'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Alert, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material';
import { FormLabel } from 'react-bootstrap';
function PlugAndPlay() {

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        companyWebsite: "",
        investerType: "",
        deploying: "",

    });
    const [isMeetingScheduled, setIsMeetingScheduled] = useState(false);


    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        if (!formData.firstName.trim()) {
            setToastStatus('error');
            setToastText('First Name is required.');
            setToastShow(true);
            return false;
        }
        if (!formData.lastName.trim()) {
            setToastStatus('error');
            setToastText('Last Name is required.');
            setToastShow(true);
            return false;
        }
        if (!formData.email.trim()) {
            setToastStatus('error');
            setToastText('Email is required.');
            setToastShow(true);
            return false;
        }
        // Email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setToastStatus('error');
            setToastText('Enter a valid email address.');
            setToastShow(true);
            return false;
        }
        if (!formData.company.trim()) {
            setToastStatus('error');
            setToastText('Company name is required.');
            setToastShow(true);
            return false;
        }
        if (!formData.companyWebsite.trim()) {
            setToastStatus('error');
            setToastText('Company website is required.');
            setToastShow(true);
            return false;
        }
        if (!formData.investerType.trim()) {
            setToastStatus('error');
            setToastText('Please select an Investor type.');
            setToastShow(true);
            return false;
        }
        if (!formData.deploying.trim()) {
            setToastStatus('error');
            setToastText('Please select if you are deploying capital.');
            setToastShow(true);
            return false;
        }
        return true;
    };


    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isMeetingScheduled) {
            if (!validateForm()) return;
            const data = new FormData();
            data.append('firstName', formData.firstName);
            data.append('lastName', formData.lastName);
            data.append('email', formData.email);
            data.append('company', formData.company);
            data.append('companyWebsite', formData.companyWebsite);
            data.append('investerType', formData.investerType);
            data.append('Are you deploying capital within the next 30 days?', formData.deploying);

            const subject = `Plug and Play | New Submission from ${formData.email}`;
            data.append('_subject', subject);


            try {
                // Send form data to Basin
                const response = await fetch('https://formsubmit.co/iqlandsoftware@gmail.com', {
                    method: 'POST',
                    body: data
                });

                if (response.ok) {
                    setToastStatus('success');
                    setToastText('Form submitted successfully!');
                    setToastShow(true);
                    window.location.href = '/plugandplay/success';
                } else {
                    setToastStatus('error');
                    setToastText('There was an error submitting the form.');
                    setToastShow(true);
                }
            } catch (error) {
                setToastStatus('error');
                setToastText('There was an error submitting the form.');
                setToastShow(true);
            }
        } else {
            setToastStatus('error');
            setToastText('Please schedule a meeting first.');
            setToastShow(true);
        }
    };

    useCalendlyEventListener({
        onEventScheduled: () => {
            setIsMeetingScheduled(true);
        },
    });


    return (
        <div>
            <NavBar />
            <div className='plug_and_play'>

                <div className='plug_and_play_container'>

                    <div className='plug_and_play_main'>
                        <div className='plug_and_play_left'>
                            <div className='plug_and_play_title'>Plug and Play</div>
                            <div className='plug_and_play_sub_title'>Commerce Tech follow-up meeting</div>
                            <TextField
                                required
                                id="outlined-required"
                                label="First Name"
                                name='firstName'
                                value={formData.firstName}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Last Name"
                                name='lastName'
                                value={formData.lastName}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}
                                type='email'
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Company"
                                name='company'
                                value={formData.company}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Company Website"
                                name='companyWebsite'
                                value={formData.companyWebsite}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />


                            <FormControl
                                required
                                fullWidth
                                style={{ marginBottom: "16px" }}
                            >

                                <InputLabel id="demo-simple-select-required-label">Investor type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={formData.investerType}
                                    label="Investor type *"
                                    onChange={handleChange}
                                    name='investerType'
                                >
                                    <MenuItem value={"VC"}>VC</MenuItem>
                                    <MenuItem value={"Angel"}>Angel</MenuItem>
                                    <MenuItem value={"Institutional"}>Institutional</MenuItem>
                                    <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required>
                                <FormLabel id="demo-row-radio-buttons-group-label">Are you deploying capital within the next 30 days? *</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="deploying"
                                    value={formData.deploying}
                                    onChange={handleChange}
                                >

                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio sx={{ color: '#126342', '&.Mui-checked': { color: '#126342' } }} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio sx={{ color: '#126342', '&.Mui-checked': { color: '#126342' } }} />}
                                        label="No"
                                    />

                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className='plug_and_play_right'>
                            <div className='plug_and_play_title' style={{ fontSize: "1.5rem", fontWeight: "600" }}>Schedule</div>
                            <div style={{ marginBottom: "1rem" }}>Choose a time to connect with the founder of IQLand, Phillip Boykin</div>
                            <InlineWidget
                                // url="https://calendly.com/asamhussainkmm/30min"
                                url="https://calendly.com/phillip-boykin-iqland/30min"
                                styles={{
                                    minHeight: "300px",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                    marginBottom: "1rem",
                                    padding: "0 12px",
                                    scrollbarWidth: "thin",
                                    scrollbarColor: "#126342 #f0f0f0"
                                }}

                                prefill={{
                                    name: formData.firstName,
                                    email: formData.email
                                }}

                                pageSettings={{
                                    primaryColor: "#126342",
                                    textColor: "#126342",
                                    backgroundColor: "ffffff",

                                }}
                            />
                        </div>
                    </div>

                    <div className='plug_and_play_submit' onClick={handleSubmit}>Submit</div>
                </div>

            </div>

            <Snackbar
                open={toastShow}
                autoHideDuration={3000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToastShow(false)}
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>

        </div >
    )
}

export default PlugAndPlay