import React, { useContext, useEffect, useRef, useState } from 'react'
import './UserNav.css'
import { useNavigate } from 'react-router-dom'
import { KeyboardArrowDownOutlined, NotesOutlined } from '@mui/icons-material';
import IQList from '../util_component/iq_list/IQList';
import { AuthContext } from '../auth/AuthenticationContext';
import IQLoading from '../util_component/iq_loading/IQLoading';
import { getProfilePicture } from '../user_service/UserService';

function UserNav({ tab, handleBurgerClick }) {

  const navigate = useNavigate();

  
  const [preview, setPreview] = useState();
  const [imageLoading, setImageLoading] = useState(false);


  const { logOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [profileMenu, setProfileMenu] = useState(false);
  const profile_tabs = [
    {
      text: "Subscription",
      action: () => navigate("/settings/billing")
    },
    {
      text: "FAQ",
      action: () => navigate("/faqs")
    },
    // {
    //   text: "Favorites",
    //   action: () => navigate("/favorite")
    // },
    {
      text: "Settings",
      action: () => navigate("/settings/personal")
    },
    {
      text: "Help",
      action: () => navigate("/contact")
    },
    {
      text: "Logout",
      action: async () => {
        setLoading(true);
        await logOut();
      }
    }
  ]

  // handle outside click
  const profileTabs = useRef(null);
  const handleClickOutside = (event) => {
    if (profileTabs.current && !profileTabs.current.contains(event.target)) {
      setProfileMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    setImageLoading(true);
    try {
      getProfilePicture()
          .then((response) => {
              setPreview(response.presigned_url);
              setImageLoading(false);
          })
  }
  catch (error) {
      setPreview('/images/default_profile.png');
      setImageLoading(false);
  }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const copilotNavigation = ()=> {
    navigate("/copilot");
    // const chatId = sessionStorage.getItem("chatID");
    // if(chatId){
    //   navigate(`/copilot?chat=${chatId}`)
    // }
    // else{
    //   navigate("/copilot")
    // }
  }

  return (
    <nav className='user_nav'>
      {/* Logo iqland */}
      <div className='user_nav__burger_icon' onClick={() => handleBurgerClick()}><NotesOutlined/></div>
      <img src='/images/logo.svg' alt='IQLand' className='user_nav__logo_top' onClick={() => navigate("/")} style={{ width: "130px", height: "32px" }} />


      <div style={{display:"flex", alignItems:"center", gap:"2.5rem"}}>
        {/* tabs */}
        <ul className='user_nav__tabs'>
          <li className={tab === "h" ? "user_nav__active_tab" : null} onClick={() => navigate("/user/home")}>Home</li>
          <li className={tab === "c" ? "user_nav__active_tab" : null} onClick={() => copilotNavigation()}>Copilot</li>
          <li className={tab === "d" ? "user_nav__active_tab" : null} onClick={() => navigate("/dashboard")}>Dashboard</li>
          <li className={tab === "r" ? "user_nav__active_tab" : null} onClick={() => navigate("/reports")}>Reports</li>
        </ul>

        {/* profile area */}
        <div ref={profileTabs} className='user_nav_profile_area' >

          <div className='user_nav_profile' onClick={() => setProfileMenu(!profileMenu)}>
            <img src={imageLoading ? "/images/default_profile.png" : preview} alt='profile' />
            <KeyboardArrowDownOutlined />
          </div>
          {
            profileMenu && <IQList items={profile_tabs} />
          }
        </div>
      </div>


      {
        loading && <IQLoading message={"Logging out..."} />
      }
    </nav>
  )
}

export default UserNav